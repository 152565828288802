<template>
  <div class="payroll-deduction-scheme--benefits-employees">
    <h1>Benefits for Employees</h1>

    <p>Our Payroll Deduction Scheme is a great way to start saving up for everyday items, unexpected bills and for Christmas.</p>
    <p>Paying direct from your wages means you will also qualify for preferential loan rates.</p>
    <p>Taking out a loan with your local credit union can help you build or repair a poor credit profile.</p>
    <p>We also provide free Life Cover to all our borrowers (up to £1500) which would go towards  repaying your loan should anything happen to you.</p>
    <p>Payment is deducted from your salary every payday so you don’t have to do a thing.</p>
    <p>Let us help you with your finances so you can focus on you!</p>

<!--    <div class="pb-3">-->
<!--      <b-embed-->
<!--          type="iframe"-->
<!--          aspect="16by9"-->
<!--          src="https://www.youtube.com/embed/Pcvy9-_Iln4?si=cxtQOF2NAr7j-oJA"-->
<!--          allowfullscreen-->
<!--      ></b-embed>-->
<!--    </div>-->

  </div>
</template>

<script>
export default {
  name: 'Payroll Deduction Scheme Benefits Employees',
  metaInfo() {
    return {
      title: 'Benefits for Employees | Payroll Deduction Scheme | Cash Box Credit Union',
      meta: [
        { name: 'keywords', content: "payroll, deduction, scheme, benefits, employees" },
        { name: 'description', content: "" }
      ],
    };
  }
}
</script>

<style scoped>

</style>
